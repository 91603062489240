import { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

function Loader(props) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const loadingTimeout = setTimeout(() => {
      setShow(true);
    }, 200);

    return () => {
      clearTimeout(loadingTimeout);
    };
  }, []);

  if (show) {
    return (
      <Box {...props}>
        <LinearProgress color="primary" />
      </Box>
    );
  } else {
    return null;
  }
}

export default Loader;
