import { useEffect, useRef, useState } from "react";

import orderBy from "lodash/orderBy";
import concat from "lodash/concat";
import { cipDateFormat } from "../../utils";
import {
  getImagesFromCDS,
  getImagesOnOrderId,
  getOrderStatusLogsOnOrderID
} from "../../api";
import { useApi } from "../../context/context";

import "./OrderEvents.scss";

const OrderEvents = (props) => {
  const { order } = props;

  const { user } = useApi();

  const { OrderID } = order;
  const { token } = user;

  const isMounted = useRef(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (OrderID && token) {
      const getData = async () => {
        isMounted.current = true;

        const orderEvents = await getOrderStatusLogsOnOrderID(OrderID, token);
        const orderEventImages = await getImagesOnOrderId(OrderID, token);

        // Both image and text event needed to be merged and sorted in order
        const mergedEvents = concat(orderEventImages || [], orderEvents || []);

        // precess to add the date and time a
        // TODO: image date field should be: 'ImageTimeStamp'
        const transformDate = mergedEvents.map((item) => {
          const { FileName, ImageTimeStamp, ChangeTime, SourceLastChange } =
            item;

          let timeStamp = ChangeTime;
          let time = cipDateFormat(ChangeTime, false, true);
          let date = cipDateFormat(ChangeTime);

          if (FileName) {
            if (cipDateFormat(ImageTimeStamp)) {
              timeStamp = ImageTimeStamp;
              time = cipDateFormat(ImageTimeStamp, false, true);
              date = cipDateFormat(ImageTimeStamp);
            } else {
              timeStamp = SourceLastChange;
              time = cipDateFormat(SourceLastChange, false, true);
              date = cipDateFormat(SourceLastChange);
            }
          }

          return {
            ...item,
            time,
            date,
            timeStamp
          };
        });
        const sortedEvents = orderBy(transformDate, ["timeStamp"], ["asc"]);

        if (isMounted.current) {
          setData(sortedEvents);
          setLoading(false);
        }
      };

      // run
      getData();
    }

    return () => {
      isMounted.current = false;
    };
  }, [OrderID, token]);

  const renderRows = () => {
    if (data?.length > 0) {
      return data.map((item) => {
        const { FileName, StatusNameTo, Notes, ID, time, date } = item;

        return (
          <tr key={ID}>
            <td>{time}</td>
            <td>{date}</td>
            <td className={FileName ? "image-event" : ""}>
              {FileName ? "Bilduppladdning" : StatusNameTo}
            </td>
            <td className="notes">
              <span>{Notes}</span>
              {FileName ? (
                <>
                  <img
                    src={getImagesFromCDS(FileName, token) || ""}
                    alt="logs"
                  />
                </>
              ) : (
                ""
              )}
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan={4}>
            <p>{loading ? "Loading..." : "No data available"}</p>
          </td>
        </tr>
      );
    }
  };

  return (
    <table className="order-events__container">
      <thead>
        <tr>
          <th scope="col">TIME</th>
          <th scope="col">DATE</th>
          <th scope="col">EVENT</th>
          <th scope="col">NOTE</th>
        </tr>
      </thead>
      <tbody>{renderRows()}</tbody>
    </table>
  );
};

export default OrderEvents;
