import { useState } from "react";

import { Button, FormControl, MenuItem, Select } from "@mui/material";
import { useApi } from "../../context/context";

import styles from "./DropDown.module.scss";

function DropDown() {
  const { userCompanies, companyId, setState } = useApi();
  const [selectedCompany, setSelectedCompany] = useState(companyId);

  // useEffect(() => {
  //   if (userCompanies) {
  //     const defaultCompanyItem = userCompanies.find(
  //       (company) => company.DefaultCompany === 1
  //     );
  //     if (defaultCompanyItem) {
  //       setSelectedCompany(defaultCompanyItem.CompanyId);
  //     }
  //   }
  // }, [userCompanies]);

  if (!userCompanies || userCompanies.length === 0) return null;

  const companies = userCompanies.map(({ CompanyId, CompanyName }) => (
    <MenuItem key={CompanyId} value={CompanyId}>
      {CompanyName}
    </MenuItem>
  ));

  const handleChange = (e) => {
    const companyId = e.target.value;
    setSelectedCompany(companyId);
  };

  const handleContinue = (e) => {
    e.preventDefault();
    localStorage.setItem("companyId", selectedCompany);
    setState((prev) => ({ ...prev, companyId: selectedCompany }));
  };

  return (
    <div className={styles.container}>
      <FormControl fullWidth>
        <Select
          displayEmpty
          value={selectedCompany}
          onChange={handleChange}
          aria-label="Select Company"
        >
          <MenuItem key={"default"} value={""}>
            Choose a company
          </MenuItem>
          {companies}
        </Select>
      </FormControl>
      <Button
        variant="contained"
        color="primary"
        type="submit"
        fullWidth
        disableElevation
        sx={{ mt: styles.spacing, p: 2, borderRadius: "100px" }}
        onClick={handleContinue}
        disabled={!selectedCompany}
      >
        Continue
      </Button>
    </div>
  );
}

export default DropDown;
