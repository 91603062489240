import { useTranslation } from "react-i18next";
import { Navigate } from "react-router";
import { Box } from "@mui/material";
import {
  LoginForm,
  VerifyForm,
  DropDown,
  RenderLoginContainer
} from "../../components";
import { useApi } from "../../context/context";

import "./style.scss";

function Login() {
  const { t } = useTranslation();
  const { user, isVerified, companyId } = useApi();
  const { Email } = user;

  if (!Email) {
    // default login form
    return (
      <RenderLoginContainer title={t("login")}>
        <LoginForm />
      </RenderLoginContainer>
    );
  }

  if (!isVerified) {
    // verification form OTP
    return (
      <RenderLoginContainer title={t("verifyYourAccount")}>
        <VerifyForm user={user} />
      </RenderLoginContainer>
    );
  }

  if (!companyId) {
    return (
      <RenderLoginContainer title="Select Your Company">
        <Box>
          <p>
            Welcome! Please choose the company you’d like to access for this
            session.
          </p>
          <p>To switch companies later, simply log out and sign back in.</p>
          <br />
          <br />
          <DropDown />
        </Box>
      </RenderLoginContainer>
    );
  }

  // redirect to home
  return <Navigate to="/" />;
}

export default Login;
