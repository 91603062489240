import { useState, useEffect, memo } from "react";

import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import Geocoder from "nominatim-geocoder";
import { GOOGLE_MAPS_API_KEY } from "config";
import thumbMapStyle from "./ThumbMapStyle";
import whiteMarker from "../../assets/images/whitemarker.svg";

import styles from "./ThumbMap.module.scss";

const geocoder = new Geocoder({
  secure: true
});

const defaultMapOptions = {
  disableDefaultUI: true,
  styles: thumbMapStyle
};

const mapContainerStyle = {
  height: "118%",
  width: "100%"
};

const center = {
  // stockholm
  lat: 59.3293,
  lng: 18.0686
};

const ThumbMap = ({ zoom = 10, disable = true, to = {} }) => {
  const [latLng, setLatLng] = useState(undefined);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_MAPS_API_KEY
  });

  useEffect(() => {
    let isCancelled = false;
    if (to) {
      const getCoordinates = async () => {
        const searchParams = {
          q: `${to.address}, ${to.city}`,
          countrycodes: "se"
        };

        const [firstResult] = await geocoder.search(searchParams);

        if (!isCancelled && firstResult) {
          setLatLng({
            lat: +firstResult.lat,
            lng: +firstResult.lon
          });
        }
      };

      getCoordinates();
    }

    return () => {
      isCancelled = true;
    };
  }, [to]);

  return (
    <div className={`${styles.mapWrapper} ${disable && styles.disable}`}>
      {isLoaded && (
        <GoogleMap
          options={defaultMapOptions}
          zoom={zoom}
          center={latLng || center}
          mapContainerStyle={mapContainerStyle}
        >
          {latLng && (
            <Marker
              icon={{
                url: whiteMarker
              }}
              position={latLng}
              animation="DROP"
            />
          )}
        </GoogleMap>
      )}
    </div>
  );
};

export default memo(ThumbMap);
