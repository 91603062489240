const { REACT_APP_BASE_URL } = process.env;
const LANGUAGE_LIST = [
  { id: 1, lang: "English", code: "Eng" },
  { id: 2, lang: "Svenska", code: "Sve" }
];
const GOOGLE_MAPS_API_KEY = "AIzaSyCrYRAN0cFnBUaStPiBoOeQeVVypYHEvWU";
const REACT_APP_PAGE_SIZE = 5;
// const IS_DEBUG = NODE_ENV === "development";
const IS_DEBUG = false;
const BASE_URL = REACT_APP_BASE_URL || "";

const TEST_USER = {
  userId: "accomplice@mtab.dk",
  password: "GQ2f#P%A>dujLTYn4v5_<J",
  code: "123456",
  code2: "913230",
  Id: 77
};

export {
  LANGUAGE_LIST,
  GOOGLE_MAPS_API_KEY,
  BASE_URL,
  REACT_APP_PAGE_SIZE,
  IS_DEBUG,
  TEST_USER
};
