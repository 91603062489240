import { useState } from "react";

import { useTranslation } from "react-i18next";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useApi } from "context/context";
import { UploadCloud } from "../../assets/icons";
import { fileUpload } from "../../api/file";

import styles from "./FileUpload.module.scss";

const TYPES = ["Instructions", "TravelDocs", "Other"];

const FileUpload = ({
  order = false,
  project = false,
  orders,
  updateFileList = () => {}
}) => {
  const { user } = useApi();
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(false);
  const [file, setFile] = useState(null);
  const [type, setType] = useState("Instructions");
  const [desc, setDesc] = useState("");
  const [selectedOrder, setSelectedOrder] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUploading(true);

    const formData = new FormData();

    formData.append("file", file);
    formData.append("fileName", file?.name || "file");

    if (project) {
      formData.append("projectId", project.ProjectID);
    }

    if (selectedOrder) {
      formData.append("orderId", selectedOrder);
    }

    if (order) {
      formData.append("projectId", order.ProjectID);
      formData.append("orderId", order?.OrderID);
    }

    if (desc) {
      formData.append("description", desc);
    }

    formData.append("companyId", user?.CompanyID);
    formData.append("userId", user?.Email);
    formData.append("Id", user?.Id);
    formData.append("type", type);

    const resp = await fileUpload(formData, user?.token);

    if (resp?.status === 200) {
      setUploaded(true);
      setUploading(false);
      setOpen(false);
      updateFileList();
    } else {
      setUploading(false);
      setUploaded(false);
      setError("Error uploading file");
    }
  };

  if (open) {
    return (
      <div className={styles.uploadForm}>
        <div className={styles.upload} onClick={() => setOpen(false)}>
          <UploadCloud className={styles.uploadCloud} />
        </div>
        <form id="uploadForm" onSubmit={handleSubmit}>
          <FormControl fullWidth>
            <InputLabel id="select-label">TYPE</InputLabel>
            <Select
              labelId="select-label"
              id="select"
              value={type}
              label="Type"
              onChange={(e) => {
                setType(e.target.value);
              }}
            >
              {TYPES?.map((a) => (
                <MenuItem value={a} key={a}>
                  {a}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {orders ? (
            <>
              <br></br>
              <FormControl fullWidth>
                <InputLabel id="select-order">Order</InputLabel>
                <Select
                  labelId="select-order"
                  value={selectedOrder}
                  label="Orders"
                  onChange={(e) => {
                    setSelectedOrder(e.target.value);
                  }}
                >
                  {orders?.map(({ OrderID, Header }) => (
                    <MenuItem value={OrderID} key={OrderID}>
                      {OrderID}, {Header}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          ) : (
            ""
          )}

          <input
            type="file"
            name="file"
            onChange={(e) => {
              setFile(e.target.files[0]);
            }}
          />
          <FormControl fullWidth>
            <TextField
              id="outlined-basic"
              label="Description"
              variant="outlined"
              onChange={(e) => {
                setDesc(e.target.value);
              }}
            />
          </FormControl>
          <br />
          <br />
          {error && (
            <p style={{ color: "red", marginBottom: "1rem" }}>{error}</p>
          )}
          {uploading ? (
            <p>{t("uploading")}...</p>
          ) : (
            <div>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disableElevation
                sx={{
                  mt: styles.spacing,
                  p: 1,
                  width: "110px",
                  borderRadius: "50px"
                }}
              >
                Upload
              </Button>
              <Button
                variant="contained"
                color="secondary"
                type="cancel"
                disableElevation
                onClick={() => setOpen(!open)}
                sx={{
                  mt: styles.spacing,
                  p: 1,
                  marginLeft: "10px",
                  width: "110px",
                  borderRadius: "50px"
                }}
              >
                Cancel
              </Button>
            </div>
          )}
        </form>
      </div>
    );
  }

  return (
    <>
      {uploaded && (
        <div className="notice success">
          <p>File uploaded successfully.</p>
        </div>
      )}

      <div className={styles.upload} onClick={() => setOpen(!open)}>
        <UploadCloud className={styles.uploadCloud} />
        <p>{t("upload")}</p>
      </div>
    </>
  );
};

export default FileUpload;
