import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { MagnifyingGlass } from "../../assets/icons";

import styles from "./SearchProject.module.scss";

/**
 *
 * @param {{ searchValue: string, setInputFocus: function, setSearchValue: function, setOpacityClass: function }} props
 * @returns react element
 */
const SearchProject = (props) => {
  const { searchValue, setInputFocus, setSearchValue, setOpacityClass } = props;
  const [focused, setFocused] = useState(false);
  const [localSearchValue, setLocalSearchValue] = useState("");
  const [searched, setSearched] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setInputFocus(focused);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focused]);

  useEffect(() => {
    //console.log("searched changed", searched);
    if (searched === false) {
      setLocalSearchValue("");
    }
  }, [searched]);

  useEffect(() => {
    setFocused(false);
    setOpacityClass("noOpacity");
    setSearchValue(localSearchValue.trim());

    if (localSearchValue !== "") {
      setSearched(true);
    } else {
      setSearched(false);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localSearchValue]);

  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);

  return (
    <div className={styles.search}>
      <input
        type="text"
        onFocus={onFocus}
        onBlur={onBlur}
        value={searchValue}
        onChange={(e) => setLocalSearchValue(e.target.value)}
        placeholder={`${t("search.placeholder")} ${t(
          "search.placeholder_example"
        )}`}
      />

      <MagnifyingGlass className={styles.searchIcon} />
    </div>
  );
};

export default SearchProject;
