import { useState } from "react";

import Button from "@mui/material/Button";
import { Box, TextField } from "@mui/material";
import { Link } from "react-router";
import { useApi } from "../../context/context";
import { Spinner } from "../../assets/icons";

import styles from "./LoginForm.module.scss";
// import { TEST_USER } from "config";

// default values for login form :NOTE: should be empty after testing
const defaultValue = {
  // userid: TEST_USER.userId,
  // password: TEST_USER.password
  userid: "",
  password: ""
};

const LoginForm = () => {
  const { loginUser, errorMessage, setState } = useApi();
  const [formValue, setFormValues] = useState(defaultValue);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormValues({
      ...formValue,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { userid, password } = formValue;

    if (userid && password) {
      setIsLoading(true);
      await loginUser(userid, password);
      setIsLoading(false);
    }
  };

  return isLoading ? (
    <div>
      <Spinner />
    </div>
  ) : (
    <form onSubmit={handleSubmit} className={styles.loginForm}>
      <TextField
        id="userid-input"
        name="userid"
        label="Username"
        type="text"
        fullWidth
        variant="outlined"
        autoComplete="userid"
        required
        value={formValue.userid}
        onChange={handleChange}
        error={!!errorMessage}
        sx={{ mb: styles.spacing, backgroundColor: "white" }}
      />

      <TextField
        id="password-input"
        name="password"
        label="Password"
        type="password"
        required
        fullWidth
        variant="outlined"
        autoComplete="current-password"
        value={formValue.password}
        onChange={handleChange}
        error={!!errorMessage}
        helperText={
          errorMessage === "Error"
            ? "Sorry, we are doing maintenance to our servers. Try again in a few minutes."
            : errorMessage
        }
        sx={{ backgroundColor: "white" }}
      />

      <Button
        variant="contained"
        color="primary"
        type="submit"
        fullWidth
        disableElevation
        sx={{ mt: styles.spacing, p: 2, borderRadius: "100px" }}
      >
        Continue
      </Button>

      <Box sx={{ m: styles.spacing }}>
        <p className={styles.infoMsg}>
          Forgot your password?{" "}
          <Link
            to="/account/set-password"
            onClick={() => {
              setState((prev) => ({
                ...prev,
                errorMessage: ""
              }));
            }}
          >
            Set a new password
          </Link>{" "}
          or contact{" "}
          <a href="mailto:mymtabsupport@mtab.se">mymtabsupport@mtab.se</a>
        </p>
      </Box>
    </form>
  );
};

export default LoginForm;
