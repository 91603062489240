import { useApi } from "../../context/context";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import Loader from "../Loader/Loader";
import Notice from "../Notice/Notice";

import styles from "./Layout.module.scss";

/**
 *
 * @param {{ children, [className] }} param0
 * @returns React element
 */
const Layout = ({ children, className }) => {
  const { isVerified, isLoadingUser } = useApi();

  // get env variable and check if it is true
  const isNotice = process.env.REACT_APP_NOTICE === "true";

  return (
    <div className={styles.layoutContainer}>
      {isNotice && <Notice />}
      <Header />
      <div className={`${styles.pageWrapper}`}>
        {isLoadingUser ? <Loader /> : <>{children}</>}
      </div>
      <Footer isUserVerified={isVerified} />
    </div>
  );
};

export default Layout;
