import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import { Link } from "react-router";
import { SmallArrow } from "../../assets/icons";

import styles from "./NotFound.module.scss";

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="h1" color="primary.main">
        404 - {t("notFoundText")}
      </Typography>
      <Link to="/" className={styles.link}>
        <SmallArrow className={styles.smallArrow} color="#002c8b" />
        {t("goToStart")}
      </Link>
    </>
  );
};

export default NotFound;
